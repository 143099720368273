.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  line-height: 1.6875;
  cursor: pointer;
  color: #d65f00;
  padding: 20px 70px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1px solid #3e4647;
  font-family: 'Alegreya', serif;
  letter-spacing: 0.08em;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.5;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.button:hover,
.button:focus {
  color: #FFF;
  background-color: #d65f00;
  border-color: #d65f00;
}
.button:active {
  background-color: #8a3d00;
}
.footarea {
  width: 107.46268657%;
  margin-left: -3.73134328%;
}
.footpart {
  margin-left: 3.47222222%;
  margin-right: 3.47222222%;
}
.footpart.footpart--slim {
  width: 43.05555556%;
}
h1 {
  font-size: 24px;
  line-height: 1.5;
}
h2 {
  font-size: 32px;
  line-height: 1.375;
}
.cb-layout1 .content h2 {
  font-size: 40px;
  line-height: 0.9;
}
.cb-layout2 .content h2 {
  font-size: 70px;
  line-height: 1.14285714;
}
h3 {
  font-size: 26px;
  line-height: 1.46153846;
}
h5 {
  font-size: 26px;
  line-height: 1.46153846;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 1.2195122%;
  margin-left: 1.2195122%;
  width: 97.56097561%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area .tiny {
  width: 33.33333333%;
}
.section--one .area > .slim .part {
  width: 100%;
}
.section--two .area {
  width: 100%;
}
.section--two .area > .unit {
  margin-right: 3.04878049%;
  margin-left: 3.04878049%;
  width: 93.90243902%;
}
.section--two .area .cb-album .body,
.section--two .area .head,
.section--two .area .foot,
.section--two .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim {
  width: 43.90243902%;
}
.section--two .area > .slim .head,
.section--two .area > .slim .foot,
.section--two .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim .tiny {
  width: 33.33333333%;
}
.section--one .area {
  width: 102.5%;
  margin-left: -1.25%;
  display: flex;
  flex-wrap: wrap;
}
.section--two .area {
  width: 106.49350649%;
  margin-left: -3.24675325%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.section--two .area .unit.wide.seam {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section--two .area .unit.wide.seam .body {
  width: 1000px;
  max-width: 100%;
}
.section--two .area .unit.wide.flat .body {
  width: 1000px;
  max-width: 100%;
}
.section--two .area .unit.slim .body {
  width: 102.77777778%;
  margin-left: -1.38888889%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
/*# sourceMappingURL=./screen-medium.css.map */